var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._b(
      {
        attrs: {
          visible: _vm.visible,
          "custom-class": "imelink-contact-remark",
          "append-to-body": true,
          title: _vm.contact.displayName
        },
        on: {
          "update:visible": function($event) {
            _vm.visible = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function() {
              return [
                _c("el-button", {
                  staticClass: "imelink-contact-remark__action",
                  attrs: { size: "small", icon: "el-icon-close" },
                  on: { click: _vm.close }
                }),
                _c("el-button", {
                  staticClass: "imelink-contact-remark__action",
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                    loading: _vm.submitLoading
                  },
                  on: { click: _vm.submit }
                })
              ]
            },
            proxy: true
          }
        ])
      },
      "el-dialog",
      _vm.customattr,
      false
    ),
    [
      _vm._l(_vm.remarks, function(text, index) {
        return _c(
          "el-tag",
          {
            key: index,
            attrs: {
              closable: "",
              hit: "",
              effect: "plain",
              "disable-transitions": ""
            },
            on: {
              close: function($event) {
                return _vm.removeRemark(index)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(text) + "\n  ")]
        )
      }),
      _c("el-input", {
        staticClass: "imelink-contact-remark__input",
        attrs: { maxlength: "20", size: "small" },
        nativeOn: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.createRemark.apply(null, arguments)
          }
        },
        model: {
          value: _vm.inputValue,
          callback: function($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }