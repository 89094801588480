<template>
  <el-dialog
    :visible.sync="visible"
    custom-class="imelink-contact-remark"
    v-bind="customattr"
    :append-to-body="true"
    :title="contact.displayName"
  >
    <el-tag
      closable
      hit
      effect="plain"
      disable-transitions
      v-for="(text, index) in remarks"
      :key="index"
      @close="removeRemark(index)"
    >
      {{ text }}
    </el-tag>
    <el-input
      v-model="inputValue"
      maxlength="20"
      size="small"
      class="imelink-contact-remark__input"
      @keyup.native.enter="createRemark"
    />
    <template #footer>
      <el-button
        class="imelink-contact-remark__action"
        @click="close"
        size="small"
        icon="el-icon-close"
      >
      </el-button>
      <el-button
        class="imelink-contact-remark__action"
        type="primary"
        size="small"
        @click="submit"
        icon="el-icon-check"
        :loading="submitLoading"
      >
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { clickoutside } from "@/utils";
export default {
  name: "ContactRemark",
  inject: ["control"],
  data() {
    return {
      inputValue: "",
      contact: {},
      remarks: [],
      submitLoading: false,
      visible: false,
    };
  },
  computed: {
    customattr() {
      return {
        ...{
          width: "400px",
          showClose: false,
        },
        ...this.$attrs,
      };
    },
  },
  created() {
    clickoutside("imelink-contact-remark__input", () => {
      this.createRemark();
    });
  },
  watch: {},
  methods: {
    createRemark() {
      if (this.inputValue) {
        this.remarks.push(this.inputValue);
        this.inputValue = "";
      }
    },
    removeRemark(index) {
      this.remarks.splice(index, 1);
    },
    open(contact) {
      this.contact = contact;
      this.remarks = [...contact.remarks];
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    submit() {
      this.submitLoading = true;
      //this.remarks = this.remarks.filter(v => v);
      setTimeout(() => {
        this.control.$emit(
          "change-contact-remark",
          this.contact,
          this.remarks,
          {
            success: () => {
              this.contact.remarks = this.remarks;
              this.control.currentContact.remarks = this.remarks;
              this.submitLoading = false;
              this.close();
            },
            error: () => {
              this.submitLoading = false;
            },
          }
        );
      }, 50);
    },
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';
+b(imelink-contact-remark)
  +e(titleicon)
    font-size 24px
    color #333
  // +e(action)
  //   i
  //     padding-right 20px
  //     padding-left 20px
  //     font-size 14px
  .el-dialog__body
    padding-top 10px
    padding-bottom 10px
  .el-tag
    margin-bottom 12px
    user-select none
    color #333
    border-radius 20px
    position relative
    margin-right 14px
    padding 0 12px
  .el-tag__close
    position absolute
    top -6px
    right -6px
    background #fe0657
    color #fff
    height 16px
    width 16px
    line-height 16px
    &:before
      content: "\e6d8";
    &:hover
      background #fe0657
  .el-input
    width 140px
    input
      border-radius 20px
      border 1px dashed #666
</style>
